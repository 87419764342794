<template>
  <profile_stat_card
    :cards="cards"
    :loading="loading"
    :time="stats.addedAt"
    @update="getStats(true)"
    title="Matters"
  ></profile_stat_card>
</template>

<script>
import Stat from "@/mixins/Stat";
export default {
  name: "ass_stat",
  mixins: [Stat],
  data() {
    return {
      config: {
        storageGet: "getAssStatData",
        storageSet: "setAssStatData",
        axiosLink: "getAssStat"
      }
    };
  },
  computed: {
    cards() {
      let cards = [];
      if (Object.keys(this.stats).length === 0) return cards;
      let opened = this.stats.results
        .filter(r => r.Status !== "Closed")
        .reduce((a, b) => a + b.count, 0);
      let closed = this.stats.results
        .filter(r => r.Status === "Closed")
        .reduce((a, b) => a + b.count, 0);
      //all associations
      cards.push({
        icon: "briefcase",
        count: closed + opened,
        name: "All Legal Matters"
      });
      cards.push({
        icon: "door-open",
        count: opened,
        name: "Open Matters"
      });
      cards.push({
        icon: "door-closed",
        count: closed,
        name: "Closed Matters"
      });

      return cards;
    }
  }
};
</script>

<style scoped></style>
